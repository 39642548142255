<template>
    <div class="pd20x">
        <div class="uploadFile">
            <label for="">上传通用讲义:</label>
            <div>
                <a-upload
                    name="file"
                    :accept="'.PDF'"
                    list-type="picture"
                    @change="handleChange"
                    :customRequest="customRequestDetail"
                    :fileList='fileList'
                >
                <a-button> <a-icon type="upload" />上传通用讲义</a-button>
                </a-upload>
            </div>
        </div>
        <div class="uploadFile">
            <label for="">上传通用详情页:</label>
            <div>
                <a-upload
                    name="avatar"
                    :accept="'.jpg,.png'"
                    list-type="picture-card"
                    :customRequest="customRequest"
                    :fileList='imgList'
                >
                <img v-if="request.detail_page" :src="request.detail_page" alt="avatar" />
                <div v-else>
                    <a-icon :type="loading ? 'loading' : 'plus'" />
                    <div class="ant-upload-text">
                        上传详情页
                    </div>
                </div>
                </a-upload>
            </div>
        </div>
    </div>
</template>
<script>
import { uploadImg } from '@/libs/api'
import { editSet, getSet } from '@/libs/examapi'
export default {
    name: 'jySet',
    data(){
        return{
            fileList:[],
            imgList:[],
            loading:false,
            request:{
                notes: '',
                notes_name:'',
                detail_page: ''
            }
        }
    },
    mounted(){
        this.getData()
    },
    methods:{
        getData(){
            getSet().then(res=>{
                let {detail_page, notes, notes_name} = res
                this.fileList = [{
                    uid: '1',
                    name: notes_name,
                    status: 'done',
                    url: notes,
                }]
                Object.assign(this.request,{
                    detail_page,notes
                })
            }).catch(error=>{
                console.log(error)
            })
        },
        handleChange(info){
            let fileList = [...info.fileList];
            fileList = fileList.slice(-1);
            fileList = fileList.map(file => {
                if (file.response) {
                    file.url = file.response.url;
                }
            return file;
            });
            this.fileList = fileList;
            this.request.notes_name = fileList[0].name
        },
        customRequestDetail(data){
            let formData = new FormData()
            formData.append('file',data.file)
            uploadImg(formData).then(res=>{
               this.fileList[0].status='done'
               this.request.notes = res.fileUrl
               this.submit()
            },error=>{
                console.log(error)
            })
        },
        handleChangeImg(info){
            let fileList = [...info.fileList];
            fileList = fileList.slice(-1);
            fileList = fileList.map(file => {
            if (file.response) {
                file.url = file.response.url;
            }
            return file;
            });
            this.imgList = fileList;
        },
        customRequest(data){
            let formData = new FormData()
            formData.append('file',data.file)
            uploadImg(formData).then(res=>{
            //    this.imgList[0].status='done'
               this.request.detail_page = res.fileUrl
               this.submit()
            },error=>{
                console.log(error)
            })
        },
        submit(){
            editSet({...this.request}).then(res=>{
            }).catch(error=>{
                console.log(error)
            })
        }
    }
}
</script>
<style lang="less" scoped>
.uploadFile{
    display: flex;
    padding: 30px 0 50px;
    label{
        width: 150px;
        line-height: 32px;
    }
}
</style>