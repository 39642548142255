<template>
    <div class="pd20x flex1">
        <div class="contentBox h100pct ofA">
            <div class="radio-line">
                <div class="radio-line-item" v-for="(item, index) in tabList" :key="index" :class="{active: index == activeIndex}" @click="activeIndex = index">{{item}}</div>
            </div>
            <div v-if="activeIndex == 0">
                <classBag></classBag>
            </div>
            <div v-if="activeIndex == 3">
                <jySet></jySet>
            </div>
        </div>
    </div>
</template>
<script>
import jySet from './quick/jySet'
import classBag from './quick/classBag'
export default {
    name: 'quickSet',
    components: { classBag, jySet },
    data(){
        this.tabList = ['课程包设置', '科目设置', '轮播图设置', '通用设置']
        return{
            activeIndex: 0
        }
    }
}
</script>
<style lang="less" scoped>
.radio-line{
    padding:10px 20px 0;
    .radio-line-item{
        margin-right: 50px;
    }
}
</style>